<template>
  <b-container id="saml-connection-list" class="mt-4" fluid>
    <b-row>
      <b-col cols="6">
        <h3>List of SAML Connections</h3>
      </b-col>
      <b-col v-if="$isSudo" cols="6">
        <b-button variant="primary"
                  class="float-right"
                  :to="{ name: 'SamlCoreConnectionNew' }">
          <feather type="plus"></feather>
          New Connection
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-table
          :fields="fields" :busy="loading" :items="connections"
          outlined sticky-header="800px" striped small
          empty-text="No connections to show">

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(key)="data">
            <span v-if="data.item.is_active" :class="`dot dot-green mr-2`" title="Connection active"></span>
            <span v-else :class="`dot dot-red mr-2`" title="Connection inactive"></span>
            {{ data.item.key }}
          </template>

          <template #cell(actions)="data">
            <b-button variant="light"
                      :to="{ name: 'SamlCoreConnectionView', params: { key: data.item.key } }">
              <b-icon font-scale="1" icon="eye" aria-label="View connection"></b-icon>
            </b-button>
            <b-button variant="light"
                      :to="{ name: 'SamlCoreConnectionEdit', params: { key: data.item.key } }">
              <b-icon font-scale="1" icon="pencil" aria-label="Edit connection"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { list as listConnections } from '@/api/saml/core/connections';

export default {
  name: 'SamlCoreConnectionList',
  beforeMount() {
    this.loading = true;

    listConnections()
      .then(connections => {
        this.connections = connections;
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to list connections: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
      fields: [
        { key: 'key', label: 'Key' },
        { key: 'core_partner_key', label: 'Partner' },
        { key: 'actions', label: 'Actions' },
      ],
      connections: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  &.dot-green {
    background-color: green;
  }
  &.dot-red {
    background-color: red;
  }
}
</style>
