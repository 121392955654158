import axios from 'axios';

export function list() {
  return axios.get('/v1/saml/core/connections')
    .then(({ data }) => data);
}

export function get(key) {
  return axios.get(`/v1/saml/core/connections/${key}`)
    .then(({ data }) => data);
}

export function create(data) {
  return axios.post(
    '/v1/saml/core/connections',
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
    .then(({ data: { key } }) => key);
}

export default {};
